import { useCallback, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import * as mitekScienceSDK from '../../../../../public/lib/mitek-science-sdk';
import env from '../../../../env';
import { getAutoCaptureOptions, SdkPath } from '../config';
import { DocumentType, ActionName } from '../enums';
import { IProcessContainerProps } from './types';

export const LoadingContainer = ({
  onSetCurrentAction,
}: IProcessContainerProps) => {
  const handleCameraDisplayStarted = useCallback(() => {
    onSetCurrentAction(ActionName.AutoScanning);
  }, [onSetCurrentAction]);

  useEffect(() => {
    // camera started
    mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', handleCameraDisplayStarted);
    mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
      mode: 'AUTO_CAPTURE',
      documentType: DocumentType.Document,
      mitekSDKPath: SdkPath,
      options: getAutoCaptureOptions(env.MISNAP_SDK_LICENSE),
    });
  }, [handleCameraDisplayStarted]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="200px"
      justifyContent="center"
      textAlign="center"
    >
      <CircularProgress size="100px" sx={{ height: '100px', width: '100px' }} />
    </Box>
  );
};
