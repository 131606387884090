import { useCallback } from 'react';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import { Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import { ContinueButtonPortal } from '../../heylight/ContinueButton';
import { Headline } from '../components/Headline';
import { IProcessContainerProps } from './types';

export const baseTranslationKey =
  'fields.mitekDocumentOcrContainer.submitContainerPdf';

export const SubmitContainerPdf = ({ onSubmit }: IProcessContainerProps) => {
  const { t } = useTranslation();

  const handleResetProcess = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      <Headline
        subtitleTranslationKey={`${baseTranslationKey}.subtitle`}
        titleTranslationKey={`${baseTranslationKey}.title`}
      />
      <ContinueButtonPortal>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <BaseButton
              fullWidth={true}
              onClick={onSubmit}
              sx={{ textTransform: 'uppercase' }}
              variant="contained"
            >
              {t('fields.mitekDocumentOcrContainer.buttons.submitButton.label')}
            </BaseButton>
          </Grid>
          <Grid item={true} xs={12}>
            <BaseButton
              fullWidth={true}
              onClick={handleResetProcess}
              sx={{ borderWidth: '2px', textTransform: 'uppercase' }}
              variant="outlined"
            >
              {t(
                'fields.mitekDocumentOcrContainer.buttons.selectDifferentFileButton.label',
              )}
            </BaseButton>
          </Grid>
        </Grid>
      </ContinueButtonPortal>
    </>
  );
};
