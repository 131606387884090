import { useCallback } from 'react';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import { Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import { ContinueButtonPortal } from '../../heylight/ContinueButton';
import { Headline } from '../components/Headline';
import { ActionName } from '../enums';
import { IProcessContainerProps } from './types';

export const baseTranslationKey =
  'fields.mitekDocumentOcrContainer.submitContainerImageCapture';

export const SubmitContainerImageFromCapture = ({
  filesData,
  onRegisterGlobalEventListeners,
  onSetCurrentAction,
  onSetError,
  onSubmit,
}: IProcessContainerProps) => {
  const { t } = useTranslation();

  const handleAddMoreFileClick = useCallback(() => {
    onSetError('');
    onRegisterGlobalEventListeners();
    onSetCurrentAction(ActionName.LoadingAutocapture);
  }, [onRegisterGlobalEventListeners, onSetCurrentAction, onSetError]);

  return (
    <>
      <Headline
        subtitleTranslationKey={`${baseTranslationKey}.subtitle`}
        titleTranslationKey={`${baseTranslationKey}.title`}
        translationAtributes={{
          selectedFilesCount: filesData.length,
        }}
      />
      <ContinueButtonPortal>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <BaseButton
              fullWidth={true}
              onClick={onSubmit}
              sx={{ textTransform: 'uppercase' }}
              variant="contained"
            >
              {t('fields.mitekDocumentOcrContainer.buttons.submitButton.label')}
            </BaseButton>
          </Grid>
          <Grid item={true} xs={12}>
            <BaseButton
              fullWidth={true}
              onClick={handleAddMoreFileClick}
              sx={{ borderWidth: '2px', textTransform: 'uppercase' }}
              variant="outlined"
            >
              {t(
                'fields.mitekDocumentOcrContainer.buttons.addAnotherFileButton.label',
              )}
            </BaseButton>
          </Grid>
        </Grid>
      </ContinueButtonPortal>
    </>
  );
};
