import { ChangeEvent, MutableRefObject, useCallback } from 'react';
import { useTranslation } from '@hooks';
import * as mitekScienceSDK from '../../../../../public/lib/mitek-science-sdk';
import env from '../../../../env';
import { getProcessFrameConfig } from '../config';
import { DocumentType, MimeType } from '../enums';
import { IFileData } from '../types';
import { VisuallyHiddenInput } from './Styled';

interface IFileSelectorProps {
  filesData: IFileData[];
  inputRef: MutableRefObject<HTMLInputElement | null>;
  isLabeledExternally?: boolean;
  onAfterFileSelection?: () => void;
  onBeforeFileSelection?: () => void;
  onFileData?: (fileData: string) => void;
}

export const FileSelector = ({
  filesData,
  inputRef,
  isLabeledExternally,
  onAfterFileSelection,
  onBeforeFileSelection,
  onFileData,
}: IFileSelectorProps) => {
  const { t } = useTranslation();

  // We only support image file stitching
  const getAcceptedMimeType = useCallback(
    () =>
      filesData.length > 0
        ? `${MimeType.Jpg}, ${MimeType.Png}`
        : `${MimeType.Jpg}, ${MimeType.Png}, ${MimeType.Pdf}`,
    [filesData],
  );

  const handleManualFileCaptureResult = useCallback(
    (file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileData = reader?.result;

        // the result type is dependent on the reader function used and could return an ArrayBuffer too
        if (typeof fileData === 'string') {
          onFileData?.(fileData);
        }
        reader.onerror = error => {
          throw new Error(`Income document PDF selection failed: ${error}`);
        };
      };
      reader.readAsDataURL(file);
    },
    [onFileData],
  );

  const handleUserFileSelected = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onBeforeFileSelection?.();

      const file = event.target.files?.[0];
      if (file?.type === MimeType.Pdf) {
        handleManualFileCaptureResult(file);
      } else {
        mitekScienceSDK.cmd(
          'PROCESS_FRAME',
          getProcessFrameConfig(
            file,
            DocumentType.Document,
            env.MISNAP_SDK_LICENSE,
          ),
        );
      }

      onAfterFileSelection?.();
    },
    [
      handleManualFileCaptureResult,
      onAfterFileSelection,
      onBeforeFileSelection,
    ],
  );

  return (
    <VisuallyHiddenInput
      accept={getAcceptedMimeType()}
      multiple={false}
      onChange={handleUserFileSelected}
      ref={inputRef}
      role="button"
      sx={{ width: '100%' }}
      type="file"
      {...(!isLabeledExternally
        ? {
            'aria-label': t('common.selectFile'),
          }
        : null)}
    />
  );
};
