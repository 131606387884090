import { useCallback, useRef } from 'react';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import { Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import { ContinueButtonPortal } from '../../heylight/ContinueButton';
import { FileSelector } from '../components/FileSelector';
import { Headline } from '../components/Headline';
import { IProcessContainerProps } from './types';

export const baseTranslationKey =
  'fields.mitekDocumentOcrContainer.submitContainerImageFileSystem';

export const SubmitContainerImageFromFileSystem = ({
  filesData,
  onRegisterGlobalEventListeners,
  onSetError,
  onSubmit,
}: IProcessContainerProps) => {
  const fileSelectorRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const toggleFileSelector = useCallback(
    () => fileSelectorRef.current?.click(),
    [],
  );

  const handleOnBeforeSelection = useCallback(() => {
    onSetError('');
    onRegisterGlobalEventListeners();
  }, [onRegisterGlobalEventListeners, onSetError]);

  return (
    <>
      <Headline
        subtitleTranslationKey={`${baseTranslationKey}.subtitle`}
        titleTranslationKey={`${baseTranslationKey}.title`}
        translationAtributes={{
          selectedFilesCount: filesData.length,
        }}
      />
      <ContinueButtonPortal>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <BaseButton
              fullWidth={true}
              onClick={onSubmit}
              sx={{ textTransform: 'uppercase' }}
              variant="contained"
            >
              {t('fields.mitekDocumentOcrContainer.buttons.submitButton.label')}
            </BaseButton>
          </Grid>
          <Grid item={true} xs={12}>
            <BaseButton
              fullWidth={true}
              onClick={toggleFileSelector}
              role={undefined}
              sx={{ borderWidth: '2px', textTransform: 'uppercase' }}
              variant="outlined"
            >
              {t(
                'fields.mitekDocumentOcrContainer.buttons.addAnotherFileButton.label',
              )}
              <FileSelector
                filesData={filesData}
                inputRef={fileSelectorRef}
                onBeforeFileSelection={handleOnBeforeSelection}
              />
            </BaseButton>
          </Grid>
        </Grid>
      </ContinueButtonPortal>
    </>
  );
};
