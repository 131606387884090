import { useCallback } from 'react';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import { Box, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { useExecuteAsync, useTranslation } from '@hooks';
import { InternalDocumentViewer } from '@transaction/components/PDFDocumentViewer/components/InternalDocumentViewer';
import { SoftErrorKeys } from '@utils';
import { uploadExperianItalyFile } from '../../../services/ExperianItalyFileUploadService';
import { ContinueButtonPortal } from '../../heylight/ContinueButton';
import { Headline } from '../components/Headline';
import { FileExtension, FileSelectionType, ActionName } from '../enums';
import { getUploadErrorTranslationKey } from '../utils/getErrorTranslationKey';
import { getFileSrc } from '../utils/getFileSrc';
import { IProcessContainerProps } from './types';

const baseTranslationKey =
  'fields.mitekDocumentOcrContainer.scanningResultContainer';

const ImageStyled = styled('img')`
  width: 100%;
  height: auto;
`;

const FileSelectionTypeToSubmitStepMap = {
  [FileSelectionType.FileSystem]: ActionName.SubmitImageFileSystemSelection,
  [FileSelectionType.ImageCapture]: ActionName.SubmitImageCaptureSelection,
};

export const ScanningResultContainer = ({
  errorCode,
  filesData,
  fileSelectionType,
  onRemoveFile,
  onSetCurrentAction,
}: IProcessContainerProps) => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const errorTranslationKey = errorCode
    ? getUploadErrorTranslationKey(errorCode)
    : undefined;
  const canSubmit = !errorTranslationKey && filesData.length > 0;
  const subtitleKey = errorTranslationKey || `${baseTranslationKey}.subtitle`;

  const { fileExtension, fileReaderResult } = filesData[filesData.length - 1];
  const isPdfFile = fileExtension === FileExtension.Pdf;
  const fileSrc = getFileSrc(fileReaderResult, isPdfFile);

  const getSubmitActionForImageFile = useCallback(() => {
    if (!fileSelectionType) {
      throw new Error('File selection type not set for file type image.');
    }
    return FileSelectionTypeToSubmitStepMap[fileSelectionType];
  }, [fileSelectionType]);

  const handleUploadSuccess = useCallback(() => {
    if (fileExtension === FileExtension.Pdf) {
      onSetCurrentAction(ActionName.SubmitPdfDocument);
    } else {
      onSetCurrentAction(getSubmitActionForImageFile());
    }
  }, [fileExtension, getSubmitActionForImageFile, onSetCurrentAction]);

  const handleConfirmClick = useExecuteAsync(
    async () => {
      const base64File = fileReaderResult.split(',')[1];
      await uploadExperianItalyFile(base64File, fileExtension).then(() => {
        handleUploadSuccess();
      });
    },
    { softErrorCode: SoftErrorKeys.retry },
  );

  const handleRetryClick = useCallback(() => {
    if (filesData.length <= 1) {
      onSetCurrentAction(ActionName.SelectProcess);
    } else {
      onSetCurrentAction(getSubmitActionForImageFile());
    }
    onRemoveFile();
  }, [
    filesData.length,
    getSubmitActionForImageFile,
    onRemoveFile,
    onSetCurrentAction,
  ]);

  return (
    <>
      <Headline
        subtitleTranslationKey={subtitleKey}
        titleTranslationKey={`${baseTranslationKey}.title`}
      />
      <Box width="100%">
        {isPdfFile ? (
          <InternalDocumentViewer
            height={isMobile ? '400px' : '600px'}
            uri={fileSrc}
          />
        ) : (
          <ImageStyled
            alt={t(`${baseTranslationKey}.imageAltText`)}
            src={fileSrc}
          />
        )}
      </Box>

      <ContinueButtonPortal>
        <Grid container={true} spacing={2}>
          {canSubmit ? (
            <Grid item={true} xs={12}>
              <BaseButton
                fullWidth={true}
                onClick={handleConfirmClick}
                sx={{ textTransform: 'uppercase' }}
                variant="contained"
              >
                {t(
                  'fields.mitekDocumentOcrContainer.buttons.submitButton.label',
                )}
              </BaseButton>
            </Grid>
          ) : null}
          <Grid item={true} xs={12}>
            <BaseButton
              fullWidth={true}
              onClick={handleRetryClick}
              sx={{ borderWidth: '2px', textTransform: 'uppercase' }}
              variant="outlined"
            >
              {t('fields.mitekDocumentOcrContainer.buttons.retryButton.label')}
            </BaseButton>
          </Grid>
        </Grid>
      </ContinueButtonPortal>
    </>
  );
};
