import { ReactElement } from 'react';
import { ActionName } from '../enums';
import { IStepContainerBase } from '../types';

interface IDisplayControllerProps extends IStepContainerBase {
  children: ReactElement;
  visibleForActions: ActionName[];
}

export const DisplayController = ({
  children,
  state,
  visibleForActions,
}: IDisplayControllerProps) =>
  visibleForActions.includes(state.action) ? children : null;
