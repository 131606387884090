import { call } from '../../utils/http';

export async function uploadExperianItalyFile(
  base64File: string,
  fileExtension: string,
) {
  return call(
    '/api/checkout-client/experian-italy/income-document-photo-upload/',
    {
      method: 'POST',
      body: JSON.stringify({
        base64File,
        fileExtension,
      }),
      disableRetry: true,
    },
  );
}
