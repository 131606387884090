import { Subtitle } from '@heidi-pay/heidi-component-library/components';
import { Box } from '@mui/material';
import { CopyBlock } from '../../../../components/CopyBlock';

type InterpolationAttributeName = 'selectedFilesCount';

interface IHeadlineProps {
  subtitleTranslationKey: string;
  titleTranslationKey: string;
  translationAtributes?: Record<InterpolationAttributeName, unknown>;
}

export const Headline = ({
  subtitleTranslationKey,
  titleTranslationKey,
  translationAtributes,
}: IHeadlineProps) => (
  <Box width="100%">
    <CopyBlock i18nKey={titleTranslationKey} />
    <Subtitle>
      <CopyBlock
        i18nKey={subtitleTranslationKey}
        value={translationAtributes}
      />
    </Subtitle>
  </Box>
);
