import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { getContractTypeTranslationKey } from '@utils/helpers/getContractTypeTranslationKey';
import { ContractType } from '../../../../../enums';
import {
  IGenerateLabelParts,
  IGetTermsAutocompleteOptions,
  ITranslatePart,
} from './types';

const translatePart = ({ name, part, translate }: ITranslatePart) =>
  translate(`fields.orderProfilePicker.${name}.parts.${part}`);

export const getTermsAutocompleteOptions = ({
  terms,
  translate,
}: IGetTermsAutocompleteOptions) =>
  terms.map(term => {
    const translation = translate(
      'fields.orderProfilePicker.orderProfileTerms.options',
    );
    return { label: `${term} ${translation}`, value: term };
  });

const formatListOfInstallmentChoices = (
  choices: number[],
  translate: Translate,
): string => {
  if (choices.length === 1) {
    return choices[0].toString();
  }
  if (choices.length === 2) {
    const translatedOr = translate(
      'fields.orderProfilePicker.orderProfileDetails.parts.or',
    );

    return `${choices[0]} ${translatedOr} ${choices[1]}`;
  }
  return `${choices[0]}, ${formatListOfInstallmentChoices(
    choices.slice(1),
    translate,
  )}`;
};

const getContractTypePart = (
  contractType: ContractType,
  translate: Translate,
) => {
  const contractTypeTranslationKey =
    getContractTypeTranslationKey(contractType);
  return `${translate(contractTypeTranslationKey)} - `;
};

const getMaximumTermPart = (maximumTerm: number, translate: Translate) => {
  const upToPart = translate(
    'fields.orderProfilePicker.orderProfileDetails.parts.upTo',
  );

  const monthsPart = translate(
    'fields.orderProfilePicker.orderProfileDetails.parts.months',
  );

  return `${upToPart} ${maximumTerm} ${monthsPart}`;
};

const getSignaturePart = (
  contractType: ContractType,
  isManualAgreementSigning: boolean,
  translate: Translate,
): string | null => {
  if (isManualAgreementSigning) {
    return translate('common.manualSignature');
  }

  return contractType === ContractType.RegulatedCredit
    ? translate('common.digitalSignature')
    : null;
};

export const getOrderProfilePickerLabelV2 = ({
  availableTerms,
  contractType,
  labelPartsData: { isManualAgreementSigning, name: opName },
  translate,
}: IGenerateLabelParts) => {
  const parts = [];

  if (opName) {
    parts.push(`${opName} - `);
  }

  const signatureChunk = getSignaturePart(
    contractType,
    isManualAgreementSigning,
    translate,
  );
  if (signatureChunk) {
    parts.push(`${signatureChunk} - `);
  }

  parts.push(getContractTypePart(contractType, translate));

  parts.push(getMaximumTermPart(Math.max(...availableTerms), translate));

  return parts.join('');
};

export const getOrderProfilePickerLabel = ({
  availableTerms,
  labelPartsData: {
    depositPercentageFormatted,
    isManualAgreementSigning,
    name: opName,
    usesEqualPaymentsLabel,
  },
  name: fieldName,
  translate,
}: IGenerateLabelParts) => {
  const parts = [];

  if (opName) {
    parts.push(`${opName} - `);
  }

  if (isManualAgreementSigning) {
    parts.push(
      `${translate('fields.selectPicker.instore.manualSignature')} - `,
    );
  }

  const formattedChoices = formatListOfInstallmentChoices(
    availableTerms,
    translate,
  );

  if (usesEqualPaymentsLabel) {
    parts.push(
      `${formattedChoices} `,
      translatePart({ name: fieldName, part: 'equalPayments', translate }),
    );
  } else {
    parts.push(
      `${depositPercentageFormatted} `,
      translatePart({ name: fieldName, part: 'upfront', translate }),
      ', ',
      `${formattedChoices} `,
      translatePart({ name: fieldName, part: 'payments', translate }),
    );
  }

  return parts.join('');
};
